<template>
	<div class="notData flex_d_c_c">
		<!-- 没有数据 -->
		<img v-if="imgType == 1" src="@img/common/kong.png" class="notImg" alt="">
		<img v-if="imgType == 2" class="weigoumaiImg" src="@img/detail/weikaishi.png" alt="" />
		<img v-if="imgType == 3" class="gantanImg" src="@img/detail/gantao.png" alt="" />
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: 'NotData',
		props: {
			imgType: {
				type: Number,
				default: 1
			}
		},
		data() {
			return {

			}
		},
		methods: {
			
		}
	}
</script>

<style scoped lang="less">
	.notData {
		padding: 100px 0;
		
		p {
			margin: 30px 0;
			color: #909399;
		}
		
		.notImg {
			width: 30%;
			max-width: 200px;
		}
		
		.weigoumaiImg {
			width: 120px;
		}
		.gantanImg {
			width: 40px;
		}
		
		
	}
</style>