//补0
const add0 = (m) => {
	return m < 10 ? '0' + m : m
}
//时间戳转时间 返回对象{y, m, d, h, mm, s}
const format = (timestamp) => {
	if (!timestamp) return;
	if (parseInt(timestamp) < 10000000000) {
		timestamp = parseInt(timestamp) * 1000
	}
	var time = new Date(timestamp); //中国标准时间
	var y = time.getFullYear();
	var m = add0(time.getMonth() + 1);
	var d = add0(time.getDate());
	var h = add0(time.getHours());
	var mm = add0(time.getMinutes());
	var s = add0(time.getSeconds());
	return {y, m, d, h, mm, s}
}

// 时间戳转时间 固定格式 2024-04-17 17:11:44
const timestampToTime = (timestamp) => {
	if (!timestamp) return ""
	var date = new Date(timestamp + 8 * 3600 * 1000); // 增加8小时
	return date.toJSON().substr(0, 19).replace('T', ' ');
}

// 时间戳获取倒计时对象
const countDown = (timestamp) => {
	var nowTime = +new Date();
	var times = timestamp - nowTime, //时间戳之差
		d = add0(Math.floor(times / (1000 * 60 * 60 * 24))), //计算天数
		h = add0(Math.floor(times / (1000 * 60 * 60) % 24)), //计算小时数
		mm = add0(Math.floor(times / (1000 * 60) % 60)), //计算分钟数
		s = add0(Math.floor(times / 1000 % 60)); //计算秒数
	if(times <= 0) {
		return { d: '00', h: '00', mm: '00', s: '00' }
	}
	return { d, h, mm, s }
}


export default {
	format,
	timestampToTime,
	countDown
}