import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '@/store';

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const router = new VueRouter({
	routes: [{
			path: '/',
			redirect: '/home',
			meta: {
				title: '未来学习中心'
			}
		},
		{
			path: '/index',
			name: 'Index',
			component: () => import('@/views/index.vue'),
			meta: {
				title: '未来学习中心'
			},
			children: [{
				path: '/home',
				name: 'Home',
				meta: {
					title: '未来学习中心'
				},
				component: () => import('@/views/home.vue')
			}, {
				path: '/lecture/detail/:id',
				name: 'LectureDetail',
				component: () => import('@/views/lecture/detail.vue'),
				meta: {
					title: '课程详情'
				}
			}, {
				path: '/lecture/tuijian',
				name: 'TuiJian',
				component: () => import('@/views/lecture/tuijian.vue'),
				meta: {
					title: '课程推荐'
				}
			}, {
				path: '/lecture',
				name: 'Lecture',
				component: () => import('@/views/list/lecture.vue'),
				meta: {
					title: '科研讲座'
				}
			}, {
				path: '/tool',
				name: 'Tool',
				component: () => import('@/views/list/tool.vue'),
				meta: {
					title: '科研工具'
				}
			}, {
				path: '/thesis',
				name: 'Thesis',
				component: () => import('@/views/list/thesis.vue'),
				meta: {
					title: '论文复现'
				}
			}, {
				path: '/vip',
				name: 'Vip',
				component: () => import('@/views/vip/index.vue'),
				meta: {
					title: '充值活动'
				}
			}, {
				path: '/giveLecture',
				name: 'GiveLecture',
				component: () => import('@/views/giveLecture/index.vue'),
				meta: {
					title: '我要讲课'
				}
			}, {
				path: '/giveLecture/form',
				name: 'GiveLectureFrom',
				component: () => import('@/views/giveLecture/formPage.vue'),
				meta: {
					title: '我要讲课表单'
				}
			}, {
				path: '/giveLecture/records',
				name: 'GiveLectureRecords',
				component: () => import('@/views/giveLecture/records.vue'),
				meta: {
					title: '申请记录'
				}
			}, {
				path: '/giveLecture/success',
				name: 'GiveLectureSuccess',
				component: () => import('@/views/giveLecture/success.vue'),
				meta: {
					title: '提交成功'
				}
			}, {
				path: '/user/center',
				name: 'UserCenter',
				component: () => import('@/views/user/center.vue'),
				meta: {
					title: '个人中心'
				}
			}, {
				path: '/web/liveQ',
				name: 'WebLiveQ',
				component: () => import('@/views/ask/web/liveQ.vue'),
				meta: {
					title: '直播答疑问题收集'
				}
			}]
		},
		// 手机端
		{
			path: '/mobile',
			name: 'Mobile',
			component: () => import('@/views/mobile/index.vue'),
			children: [{
				path: 'giveLecture',
				name: 'GiveLectureIndex',
				component: () => import('@/views/mobile/giveLecture/index.vue'),
				meta: {
					title: '我要讲课'
				}
			},{
				path: 'form',
				name: 'MobileForm',
				component: () => import('@/views/mobile/giveLecture/formPage.vue'),
				meta: {
					title: '我要讲课报名'
				}
			}, {
				path: 'success',
				name: 'MobileSuccess',
				component: () => import('@/views/mobile/giveLecture/success.vue'),
				meta: {
					title: '提交成功'
				}
			}, {
				path: 'records',
				name: 'MobileRecords',
				component: () => import('@/views/mobile/giveLecture/records.vue'),
				meta: {
					title: '申请记录'
				}
			} ]
		},
		{
			path: '/noPermission',
			name: 'NoPermission',
			component: () => import('@/views/noPermission.vue'),
			meta: {
				title: '无权限'
			}
		},
		{
			path: '/404',
			component: () => import('@/views/404.vue')
		},
		{
			path: '*',
			redirect: '/404'
		}
	]
})

// 路由跳转前
router.beforeEach((to, from, next) => {
	// 可用于拦截导航并执行一些操作，例如验证用户身份、权限控制等。
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next();
	
})

// 路由跳转后
router.afterEach((to, from) => {
	window.scrollTo(0, 0) // 每次路由改变滚动条都回到顶部
})

export default router