import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@css/index.css'

import store from './store'

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.$message = Element.Message
Vue.use(Element)

Vue.config.productionTip = false

import common from '@/utils/common.js';
Vue.prototype.$common = common;

import NotData from '@/components/NotData'
Vue.component('NotData', NotData)

import { List, Cell } from 'vant'
import 'vant/lib/index.css';
Vue.use(List);
Vue.use(Cell);


new Vue({
	router,
	store,
	render: (h) => h(App)
}).$mount('#app')
